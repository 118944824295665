.bg-primary {
    background-color: #50d6b6 !important
}

.bg-secondary {
    background-color: #0065A7 !important
}

.bg-success {
    background-color: #67d167 !important
}

.bg-danger {
    background-color: #d65050 !important
}

.bg-warning {
    background-color: #eb9d09 !important
}

.bg-info {
    background-color: #3491CF !important
}

.bg-yellow {
    background-color: #fe0 !important
}

.text-primary {
    color: #50d6b6 !important
}

.text-secondary {
    color: #0065A7 !important
}

.text-success {
    color: #67d167 !important
}

.text-danger {
    color: #d65050 !important
}

.text-warning {
    color: #eb9d09 !important
}

.text-info {
    color: #3491CF !important
}

.text-yellow {
    color: #fe0 !important
}

.btn {
    font-family: "aventabold"
}

.btn-custom {
    background-color: #7ce0c8;
    border-radius: 25px;
    color: #0065A7;
    padding: 12px 22px
}

.btn-custom:hover {
    background-color: #fe0
}

.btn-custom-link {
    color: #0065A7;
    padding: 12px 22px;
    text-decoration: underline
}

.btn-custom-link:hover {
    color: #5f5f5f
}

.btn-custom-invert {
    background-color: transparent;
    border-radius: 25px;
    border: 2px solid #0065A7;
    color: #0065A7;
    padding: 12px 22px
}

.btn-custom-invert:hover {
    background-color: #0065A7;
    color: #fe0
}

p,
span,
label,
a,
ul,
ol,
li,
div,
input {
    font-family: "aventamedium";
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 24px
}

p {
    color: #5f5f5f
}

p.lead {
    font-size: 20px
}

mark {
    background-color: #d3f5ed
}

blockquote {
    font-size: 20px
}

h1 {
    font-family: "aventaextrabold";
    font-size: 2.875em
}

@media screen and (max-width: 1199px) {
    h1 {
        font-size: 2.5em
    }
}

h2 {
    font-family: "aventaextrabold";
    font-size: 35px
}

h3 {
    font-family: "aventabold";
    font-size: 30px
}

h4 {
    font-family: "aventaextrabold";
    font-size: 28px
}

h5 {
    font-family: "aventaextrabold";
    font-size: 25px
}

h6 {
    font-family: "aventaextrabold";
    font-size: 18px
}

.electric_indigo {
    color: #0065A7
}

.caribbean_green {
    color: #50d6b6
}

.energetic_yellow {
    color: #fe0
}

label.form-label {
    color: #0065A7;
    font-family: "aventabold"
}

.form-control::-webkit-input-placeholder {
    color: #7CC7F9 !important
}

.form-control:-moz-placeholder {
    color: #7CC7F9 !important;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #7CC7F9 !important;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #7CC7F9 !important
}

.form-control:disabled {
    background-color: #d6d6d6
}

.form-control:disabled[readonly] {
    background-color: #f0f0f0
}

.form-control[readonly] {
    background-color: #f0f0f0
}

.form-control:disabled,
.form-control[readonly] {
    color: #5f5f5f
}

.form-control:disabled::-webkit-input-placeholder,
.form-control[readonly]::-webkit-input-placeholder {
    color: #5f5f5f !important
}

.form-control:disabled:-moz-placeholder,
.form-control[readonly]:-moz-placeholder {
    color: #5f5f5f !important;
    opacity: 1
}

.form-control:disabled::-moz-placeholder,
.form-control[readonly]::-moz-placeholder {
    color: #5f5f5f !important;
    opacity: 1
}

.form-control:disabled:-ms-input-placeholder,
.form-control[readonly]:-ms-input-placeholder {
    color: #5f5f5f !important
}

.form-control[type=password] {
    font: caption;
    color: #7CC7F9
}

.form-control[type=file] {
    color: #0065A7
}

.form-control[type=file]::-webkit-file-upload-button {
    background-color: #3491CF;
    color: #fff;
    font-family: "aventamedium"
}

.form-control[type=file]:hover::-webkit-file-upload-button {
    background-color: #0065A7 !important
}

.form-control[type=file]:disabled {
    background-color: #f0f0f0;
    color: #8d8d8d
}

.form-control[type=file]:disabled::-webkit-file-upload-button {
    background-color: #d6d6d6 !important;
    color: #a2a2a2;
    font-family: "aventamedium"
}

.form-control.form-control-color {
    max-width: 5rem
}

.form-control-plaintext,
.col-form-label {
    color: #0065A7;
    font-family: "aventamedium"
}

.color-picker-inline .form-control-color {
    display: inline-block;
    margin-right: 1em
}

.accordion .accordion-header .accordion-button {
    color: #0065A7;
    font-size: 20px
}

.accordion .accordion-item {
    box-shadow: none;
    border: 0;
    border-bottom: 1px solid #7CC7F9;
    border-radius: 0px;
    margin-bottom: 0px
}

.accordion .accordion-item:first-of-type {
    border-top: 1px solid #7CC7F9
}

.accordion .accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 0px
}

.accordion .accordion-body {
    padding-bottom: 30px
}

.accordion .accordion-button {
    border-radius: 0px
}

.accordion .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none
}

.accordion .accordion-button:focus {
    border-color: transparent;
    box-shadow: none
}

.accordion .accordion-button:after {
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    margin-left: auto;
    content: "";
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
    background-color: #a8ebdb;
    background-position: center;
    border-radius: 50px
}

.card {
    box-shadow: 0px 0px 16px #00000019 !important;
    border: 0 !important;
    border-radius: 10px !important
}

.card .card-header {
    border-radius: 10px 10px 0 0 !important;
    color: #0065A7;
    background-color: transparent;
    border-bottom: 0;
    padding: 30px
}

.card .card-header h4 {
    font-size: 20px
}

.card .card-header.card-tabs-navigation {
    padding: 30px 15px 0 15px
}

.card .card-header.card-tabs-navigation .nav-tabs {
    border-bottom: 0
}

.card .card-header.card-tabs-navigation .nav-tabs .nav-link {
    border: 0;
    margin-bottom: 0;
    background-color: #BFE6FF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-right: 3px
}

.card .card-header.card-tabs-navigation .nav-tabs .nav-link.disabled {
    color: #7CC7F9
}

.card .card-header.card-tabs-navigation .nav-tabs .nav-link.active {
    border: 0;
    margin-bottom: 0;
    background-color: #fff
}

.card .card-header.card-pills-navigation {
    padding: 15px
}

.card .card-header.card-pills-navigation .nav.nav-pills .nav-item .nav-link {
    border-radius: 10px;
    background: #BFE6FF;
    margin-right: 3px
}

.card .card-header.card-pills-navigation .nav.nav-pills .nav-item .nav-link.disabled {
    color: #7CC7F9
}

.card .card-header.card-pills-navigation .nav.nav-pills .nav-item .nav-link.active {
    background-color: #fff
}

.card .card-header-image {
    height: 230px;
    background-position: center;
    background-size: cover
}

.card .card-body {
    padding: 30px
}

.card .card-body .title-card-body {
    font-size: 20px;
    padding-bottom: 16px;
    color: #0065A7
}

.card .subtitle-card {
    font-size: 18px;
    color: #0065A7
}

.card .text-card {
    font-size: 16px;
    color: #5f5f5f;
    margin-bottom: 25px
}

.card .text-card .table tr:first-child {
    border-top: 1px solid #BFE6FF
}

.card .text-card .table td {
    border-color: #BFE6FF;
    color: #0065A7
}

.card.image-overlay-container .card-img {
    border-radius: 10px
}

.card.image-overlay-container .mask-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0065A7;
    opacity: .8;
    border-radius: 10px
}

.card .horizontal-card-img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-position: center;
    background-size: cover
}

.body-navbar {
    height: 100vh
}

.navbar {
    margin-bottom: 20px
}

.navbar.navbar-header {
    background-color: #0065A7;
    padding: 1em
}

.navbar .nav-link {
    font-size: 16px;
    color: #fff
}

.navbar .nav-link:hover,
.navbar .nav-link:focus {
    color: #50d6b6
}

.navbar .nav-link.active {
    border-bottom: 2px solid #50d6b6
}

.navbar .nav-item-icon {
    background-color: #50d6b6;
    padding: 13px 12px;
    border-radius: 50px;
    margin-left: 3em
}

.navbar .nav-item-icon:hover {
    background-color: #fe0;
    cursor: pointer
}

.navbar .custom-toggler.navbar-toggler:focus {
    box-shadow: none
}

.navbar .custom-toggler.navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255 255 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")
}

@media screen and (min-width: 992px) {
    .navbar .btn-close {
        display: none
    }
}

@media screen and (max-width: 991px) {
    .navbar .nav-item-icon {
        margin-left: 0em;
        text-align: center
    }

    .navbar .nav-item {
        margin-top: .5em
    }

    .navbar .navbar-nav {
        height: 100%
    }

    .navbar .nav-link {
        display: inline-block
    }

    .navbar .navbar-toggler {
        padding: .25rem 0rem
    }

    .navbar .btn-close {
        opacity: 1;
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
    }
}

.navbar .navbar-collapse.show,
.navbar .navbar-collapse.collapsing {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh !important;
    background-color: #0065A7;
    padding: 30px
}

.navbar .search-form-nav {
    position: relative
}

@media screen and (min-width: 992px) {
    .navbar .search-form-nav {
        margin-left: 2em;
        margin-right: 1em
    }
}

@media screen and (max-width: 991px) {
    .navbar .search-form-nav {
        margin-bottom: 30px
    }
}

.navbar .search-form-nav button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: auto;
    background-color: #3491CF;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.navbar .search-form-nav button svg {
    fill: #fff
}

.custom-sidebar {
    background-color: #3491CF
}

.custom-sidebar hr {
    background-color: #fff
}

.custom-sidebar .text-sidebar-header {
    margin-left: 10px;
    font-family: "aventabold"
}

.custom-sidebar .dropdown-toggle {
    outline: 0
}

.custom-sidebar .nav.nav-pills .nav-link {
    height: 50px;
    padding: 10px 30px;
    border-bottom: 0;
    border-radius: 10px;
    margin-bottom: 10px
}

.custom-sidebar .nav.nav-pills .nav-link.link-item-sidebar span {
    text-decoration: underline
}

.custom-sidebar .nav.nav-pills .nav-link span {
    color: #fff;
    font-family: "aventabold"
}

.custom-sidebar .nav.nav-pills .nav-link.active {
    background-color: #fff
}

.custom-sidebar .nav.nav-pills .nav-link.active span {
    color: #3491CF
}

.custom-sidebar .nav.nav-pills .nav-link:hover {
    background-color: rgba(255, 255, 255, .8)
}

.custom-sidebar .nav.nav-pills .nav-link:hover span {
    color: #3491CF
}

.custom-sidebar .nav.nav-pills .nav-link:hover svg {
    fill: #3491CF
}

.custom-sidebar .nav.nav-pills .nav-link svg {
    margin-right: .5em
}

.custom-sidebar .nav.nav-pills .nav-link.disabled {
    opacity: .8
}

.custom-sidebar .nav.nav-pills>.nav-item>.nav-link {
    border-top: 0
}

.custom-sidebar-dark {
    background-color: #fff;
    box-shadow: 0px 2px 5px #1a1e21
}

.custom-sidebar-dark hr {
    background-color: #3491CF
}

.custom-sidebar-dark .text-sidebar-header {
    margin-left: 10px;
    color: #0065A7;
    font-family: "aventabold"
}

.custom-sidebar-dark .dropdown-toggle {
    outline: 0;
    color: #3491CF !important
}

.custom-sidebar-dark .nav.nav-pills .nav-link {
    height: 50px;
    padding: 10px 30px;
    border-bottom: 0;
    border-radius: 10px;
    margin-bottom: 10px
}

.custom-sidebar-dark .nav.nav-pills .nav-link.link-item-sidebar span {
    text-decoration: underline
}

.custom-sidebar-dark .nav.nav-pills .nav-link span {
    color: #3491CF;
    font-family: "aventabold"
}

.custom-sidebar-dark .nav.nav-pills .nav-link svg {
    fill: #3491CF
}

.custom-sidebar-dark .nav.nav-pills .nav-link.active {
    background-color: #3491CF
}

.custom-sidebar-dark .nav.nav-pills .nav-link.active span {
    color: #fff
}

.custom-sidebar-dark .nav.nav-pills .nav-link.active svg {
    fill: #fff
}

.custom-sidebar-dark .nav.nav-pills .nav-link:hover {
    background-color: #BFE6FF
}

.custom-sidebar-dark .nav.nav-pills .nav-link:hover span {
    color: #3491CF
}

.custom-sidebar-dark .nav.nav-pills .nav-link:hover svg {
    fill: #3491CF
}

.custom-sidebar-dark .nav.nav-pills .nav-link svg {
    margin-right: .5em
}

.custom-sidebar-dark .nav.nav-pills .nav-link.disabled {
    opacity: .8
}

.custom-sidebar-dark .nav.nav-pills>.nav-item>.nav-link {
    border-top: 0
}

.sidebar-minimal .logo-sidebar-minimal {
    padding: 1em 0
}

.sidebar-minimal .nav.nav-pills .nav-link {
    padding: 15px 0px;
    height: 60px;
    border-radius: 0;
    margin-bottom: 0
}

.sidebar-minimal .nav.nav-pills .nav-link.active {
    border-radius: 0
}

.sidebar-minimal .nav.nav-pills .nav-link svg {
    margin-right: 0
}

.btn-primary {
    background-color: #0065A7;
    border-radius: 25px;
    border-color: #0065A7;
    color: #fff
}

.btn-primary:hover,
.btn-primary.active,
.btn-primary:focus,
.btn-primary:disabled {
    background-color: #5495C0;
    border-color: #5495C0;
    color: #fff
}

.btn-secondary {
    background-color: #0065A7;
    border-radius: 25px;
    border-color: #0065A7;
    color: #fe0
}

.btn-secondary:hover,
.btn-secondary.active,
.btn-secondary:focus,
.btn-secondary:disabled {
    background-color: #3491CF;
    border-color: #3491CF;
    color: #fe0
}

.btn-success {
    background-color: #67d167;
    border-radius: 25px;
    border-color: #67d167;
    color: #fff
}

.btn-success:hover,
.btn-success.active,
.btn-success:focus,
.btn-success:disabled {
    background-color: #77e877;
    border-color: #77e877;
    color: #fff
}

.btn-danger {
    background-color: #d65050;
    border-radius: 25px;
    border-color: #d65050;
    color: #fff
}

.btn-danger:hover,
.btn-danger.active,
.btn-danger:focus,
.btn-danger:disabled {
    background-color: #f45b5b;
    border-color: #f45b5b;
    color: #fff
}

.btn-warning {
    background-color: #eb9d09;
    border-radius: 25px;
    border-color: #eb9d09;
    color: #fff
}

.btn-warning:hover,
.btn-warning.active,
.btn-warning:focus,
.btn-warning:disabled {
    background-color: #f8bb48;
    border-color: #f8bb48;
    color: #fff
}

.btn-info {
    background-color: #3491CF;
    border-radius: 25px;
    border-color: #3491CF;
    color: #fff
}

.btn-info:hover,
.btn-info.active,
.btn-info:focus,
.btn-info:disabled {
    background-color: #0065A7;
    border-color: #0065A7;
    color: #fff
}

.btn-light {
    background-color: #f9fafb;
    border-radius: 25px;
    border-color: #f9fafb;
    color: #1a1e21
}

.btn-light:hover,
.btn-light.active,
.btn-light:focus,
.btn-light:disabled {
    background-color: #f9fafb;
    border-color: #f9fafb;
    color: #1a1e21
}

.btn-dark {
    background-color: #1a1e21;
    border-radius: 25px;
    border-color: #1a1e21;
    color: #f9fafb
}

.btn-dark:hover,
.btn-dark.active,
.btn-dark:focus,
.btn-dark:disabled {
    background-color: #1a1e21;
    border-color: #1a1e21;
    color: #f9fafb
}

.btn-link {
    color: #0065A7
}

.btn-link:hover {
    color: #3491CF
}

.btn-outline-primary {
    border-radius: 25px;
    border-color: #50d6b6;
    color: #0065A7
}

.btn-outline-primary:hover,
.btn-outline-primary.active,
.btn-outline-primary:focus,
.btn-outline-primary:disabled {
    background-color: #50d6b6;
    border-color: #50d6b6
}

.btn-outline-secondary {
    border-radius: 25px;
    border-color: #0065A7;
    color: #0065A7
}

.btn-outline-secondary:hover,
.btn-outline-secondary.active,
.btn-outline-secondary:focus,
.btn-outline-secondary:disabled {
    background-color: #0065A7;
    border-color: #0065A7
}

.btn-outline-success {
    border-radius: 25px;
    border-color: #67d167;
    color: #67d167
}

.btn-outline-success:hover,
.btn-outline-success.active,
.btn-outline-success:focus,
.btn-outline-success:disabled {
    background-color: #67d167;
    border-color: #67d167
}

.btn-outline-danger {
    border-radius: 25px;
    border-color: #d65050;
    color: #d65050
}

.btn-outline-danger:hover,
.btn-outline-danger.active,
.btn-outline-danger:focus,
.btn-outline-danger:disabled {
    background-color: #d65050;
    border-color: #d65050
}

.btn-outline-warning {
    border-radius: 25px;
    border-color: #eb9d09;
    color: #eb9d09
}

.btn-outline-warning:hover,
.btn-outline-warning.active,
.btn-outline-warning:focus,
.btn-outline-warning:disabled {
    background-color: #eb9d09;
    border-color: #eb9d09;
    color: #fff
}

.btn-outline-info {
    border-radius: 25px;
    border-color: #3491CF;
    color: #3491CF
}

.btn-outline-info:hover,
.btn-outline-info.active,
.btn-outline-info:focus,
.btn-outline-info:disabled {
    background-color: #3491CF;
    border-color: #3491CF;
    color: #fff
}

.btn-outline-light {
    border-radius: 25px;
    border-color: #f9fafb;
    color: #f9fafb
}

.btn-outline-light:hover,
.btn-outline-light.active,
.btn-outline-light:focus,
.btn-outline-light:disabled {
    background-color: #f9fafb;
    border-color: #f9fafb;
    color: #fff
}

.btn-outline-dark {
    border-radius: 25px;
    border-color: #1a1e21;
    color: #1a1e21
}

.btn-outline-dark:hover,
.btn-outline-dark.active,
.btn-outline-dark:focus,
.btn-outline-dark:disabled {
    background-color: #1a1e21;
    border-color: #1a1e21
}

.alert svg path.fa-primary {
    fill: transparent
}

.alert .text-alert {
    padding-left: 16px
}

.alert.alert-primary {
    color: #0065A7;
    background-color: #BFE6FF;
    border-color: #BFE6FF
}

.badge {
    border-radius: 50px
}

.badge.bg-primary {
    background-color: #50d6b6 !important;
    color: #fff
}

.badge.bg-secondary {
    background-color: #0065A7 !important;
    color: #fe0
}

.badge.bg-success {
    background-color: #67d167 !important;
    color: #fff
}

.badge.bg-danger {
    background-color: #d65050 !important;
    color: #fff
}

.badge.bg-warning {
    background-color: #eb9d09 !important;
    color: #fff
}

.badge.bg-info {
    background-color: #3491CF !important;
    color: #fff
}

.breadcrumb {
    padding-left: 0
}

.breadcrumb li {
    display: inline-block;
    padding-left: .2rem;
    padding-right: .2rem;
    text-transform: none;
    font-size: 12px;
    font-weight: normal;
    color: #5f5f5f;
    font-family: "aventabold"
}

.breadcrumb li a {
    color: #0065A7;
    font-size: 12px;
    font-family: "aventabold"
}

.breadcrumb li a:hover {
    text-decoration: underline
}

.breadcrumb li strong {
    font-weight: 400
}

.breadcrumb .separator {
    color: #5f5f5f
}

.list-group.list-group-numbered>li::before {
    color: #0065A7
}

.list-group .fw-bold {
    color: #0065A7;
    font-family: "aventabold"
}

.list-group .list-group-item {
    border-bottom: 1px solid #BFE6FF;
    padding: 16px
}

.modal-header {
    color: #0065A7;
    border-bottom: 0
}

.modal-footer {
    border-top: 0
}

.modal-content {
    border-radius: 10px;
    border: 1px solid #BFE6FF
}

.tooltip.show {
    opacity: 1
}

.tooltip .tooltip-inner {
    background-color: #0065A7
}

.tooltip[data-popper-placement=bottom] .tooltip-arrow::before {
    border-bottom-color: #0065A7
}

.tooltip[data-popper-placement=left] .tooltip-arrow::before {
    border-left-color: #0065A7
}

.tooltip[data-popper-placement=right] .tooltip-arrow::before {
    border-right-color: #0065A7
}

.tooltip[data-popper-placement=top] .tooltip-arrow::before {
    border-top-color: #0065A7
}

.toast {
    border-radius: 10px;
    box-shadow: 0px 0px 16px #00000019;
    border: 0
}

.toast .toast-header {
    color: #0065A7;
    border-bottom: 1px solid #3491CF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px
}

.toast .toast-header small {
    color: #5f5f5f
}

.toast .toast-header .btn-close {
    opacity: 1;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234F0BCD'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
}

.toast .toast-header svg {
    margin-right: 10px
}

.toast .toast-body {
    padding: 20px;
    color: #5f5f5f
}

.nav .nav-link {
    color: #0065A7;
    text-decoration: underline;
    font-family: "aventabold"
}

.nav .nav-link.active {
    text-decoration: none
}

.nav .nav-link.disabled {
    text-decoration: none;
    color: #BFE6FF
}

.nav.nav-tabs {
    border-bottom: 1px solid #BFE6FF
}

.nav.nav-tabs .nav-link {
    text-decoration: none
}

.nav.nav-tabs .nav-link.active {
    border-color: #BFE6FF #BFE6FF #fff;
    color: #0065A7
}

.nav.nav-pills .nav-link {
    text-decoration: none;
    padding: 14px 30px
}

.nav.nav-pills .nav-link.active {
    background-color: #7ce0c8;
    color: #0065A7;
    border-radius: 10px
}

.custom-range::-webkit-slider-thumb {
    background: #50d6b6
}

.custom-range::-moz-range-thumb {
    background: #50d6b6
}

.custom-range::-ms-thumb {
    background: #50d6b6
}

.pagination {
    margin: 2em
}

.pagination .current {
    padding: 0;
    background: transparent
}

.pagination .current a {
    color: #fff;
    background-color: #3491CF;
    border: 1px solid transparent;
    opacity: 1
}

.pagination li {
    background: transparent
}

.pagination li a {
    color: #0065A7;
    background-color: #7ce0c8;
    border: 1px solid transparent;
    border-radius: 50px;
    padding-top: 8px;
    width: 60px;
    height: 40px;
    text-align: center;
    margin: 0 .5em;
    font-weight: bold
}

.pagination li a:hover {
    color: #fff;
    opacity: .8;
    background-color: #0065A7
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
    border-radius: 50px
}

.progress {
    background-color: #e8e8e8;
    border-radius: 23px;
    margin-bottom: 1em
}

.progress .progress-bar:first-of-type {
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px
}

.progress .progress-bar:last-of-type {
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px
}

.input-group .dropdown-menu {
    border: 0;
    box-shadow: 0px 0px 16px #00000019
}

.input-group .dropdown-menu a {
    color: #0065A7
}

.input-group .dropdown-menu .dropdown-divider {
    border-top: 1px solid #7CC7F9
}

.input-group .input-group-text {
    background-color: #BFE6FF;
    color: #0065A7
}

.input-group ::-webkit-input-placeholder {
    color: #7CC7F9
}

.input-group ::-moz-placeholder {
    color: #7CC7F9
}

.input-group :-ms-input-placeholder {
    color: #7CC7F9
}

.input-group :-moz-placeholder {
    color: #7CC7F9
}

.input-group .btn {
    background-color: #3491CF;
    color: #fff
}

.input-group .btn:hover {
    background-color: #0065A7
}

.form-check .form-check-input {
    border: 1px solid #7CC7F9 !important
}

.form-check .form-check-input:checked {
    background-color: #0065A7;
    border-color: #0065A7
}

.checkbox-toggle-button .btn-primary,
.radio-toggle-button .btn-primary {
    background-color: #a8ebdb;
    border-color: #a8ebdb
}

.checkbox-toggle-button .btn-check:checked+.btn-primary,
.radio-toggle-button .btn-check:checked+.btn-primary {
    background-color: #50d6b6;
    border-color: #50d6b6;
    color: #0065A7
}

.outlined-styles .btn-check:checked+.btn-outline-secondary {
    border-radius: 25px;
    border-color: #50d6b6;
    color: #fff;
    background-color: #0065A7
}

.form-floating label,
.form-floating .form-control,
.form-floating .form-select {
    color: #0065A7
}

.form-select {
    color: #0065A7
}

.form-select option {
    padding: 5px 10px;
    border-radius: 5px
}

.form-select option:checked {
    background-color: #BFE6FF;
    color: #0065A7
}

.form-select:disabled {
    color: #5f5f5f
}

.dropdown .dropdown-menu {
    border-radius: 5px;
    border: 0;
    box-shadow: 0px 0px 16px #00000019
}

.dropdown .dropdown-menu a {
    color: #0065A7
}

.dropdown .dropdown-menu a:hover {
    background-color: #d3f5ed
}

.dropdown .dropdown-menu a.disabled {
    color: #BFE6FF
}

.dropdown .dropdown-menu .dropdown-divider {
    border-top: 1px solid #7CC7F9
}

.table>:not(caption)>*:last-of-type>* {
    border-bottom-width: 0
}

.table>:not(caption)>*>* {
    border-bottom-color: #BFE6FF;
    padding: 1em
}

@media screen and (max-width: 1260px) {
    .table>:not(caption)>*>* {
        min-width: 200px
    }
}

.table .table-active {
    background-color: rgba(80, 214, 182, .19);
    box-shadow: none
}

.table thead tr th,
.table tbody tr th {
    color: #0065A7
}

.table thead svg {
    margin-left: 10px
}

.table tbody {
    box-shadow: 0px 0px 16px #00000019;
    border-radius: 10px
}

.table tbody svg {
    margin-right: 10px
}

@media screen and (max-width: 1260px) {
    .table .icon_download {
        min-width: 100px !important
    }
}

.table .icon_download svg {
    fill: #50d6b6;
    margin-right: 0
}

.carousel-indicators [data-bs-target] {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #fe0;
    opacity: 1
}

.carousel-indicators [aria-current=true] {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #50d6b6
}

.carousel-control-prev {
    justify-content: left;
    opacity: 1
}

.carousel-control-prev .carousel-control-prev-icon {
    background-color: #50d6b6;
    background-size: 30px;
    background-position: 30%;
    padding: 3em 1.5em;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234F0BCD'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next {
    justify-content: flex-end;
    opacity: 1
}

.carousel-control-next .carousel-control-next-icon {
    background-color: #50d6b6;
    background-size: 30px;
    background-position: 70%;
    padding: 3em 1.5em;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234F0BCD'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

