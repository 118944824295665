// COLORS
$black: #5F5F5F;
$white: #fff;
$shadow: #00000019;

$electric_indigo: #0065A7;
$electric_indigo_75: #3491CF;
$electric_indigo_50: #7CC7F9;
$electric_indigo_25: #BFE6FF;

$energetic_yellow: #FFEE00;
$energetic_yellow_75: #FFF240;
$energetic_yellow_50: #FFF780;
$energetic_yellow_25: #FFFBBF;

$caribbean_green: #50D6B6;
$caribbean_green_75: #7CE0C8;
$caribbean_green_50: #A8EBDB;
$caribbean_green_25: #D3F5ED;

$success: #67D167;
$success_active: #77E877;
$danger: #D65050;
$danger_active: #F45B5B;
$warning: #EB9D09;
$warning_active: #F8BB48;
$light: #F9FAFB;
$dark: #1A1E21;

$form-file-button-bg: #0065A7 !default;

.bg-primary {
  background-color: $caribbean_green !important;
}

.bg-secondary {
  background-color: $electric_indigo !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-info {
  background-color: $electric_indigo_75 !important;
}

.bg-yellow {
  background-color: $energetic_yellow !important;
}

//GENERAL
.text-primary {
  color: $caribbean_green !important;
}

.text-secondary {
  color: $electric_indigo !important;
}

.text-success {
  color: $success !important;
}

.text-danger {
  color: $danger !important;
}

.text-warning {
  color: $warning !important;
}

.text-info {
  color: $electric_indigo_75 !important;
}

.text-yellow {
  color: #FFEE00 !important;
}

.btn {
  font-family: 'aventabold';
}

.btn-custom {
  background-color: #7CE0C8;
  border-radius: 25px;
  color: $electric_indigo;
  padding: 12px 22px;
}

.btn-custom:hover {
  background-color: #FFEE00;
}

.btn-custom-link {
  color: $electric_indigo;
  padding: 12px 22px;
  text-decoration: underline;
}

.btn-custom-link:hover {
  color: #5F5F5F;
}

.btn-custom-invert {
  background-color: transparent;
  border-radius: 25px;
  border: 2px solid $electric_indigo;
  color: $electric_indigo;
  padding: 12px 22px;
}

.btn-custom-invert:hover {
  background-color: $electric_indigo;
  color: #FFEE00;
}


// TYPOGRAPHY
p,
span,
label,
a,
ul,
ol,
li,
div,
input {
  font-family: 'aventamedium';
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
}

p {
  color: $black;

  &.lead {
    font-size: 20px;
  }
}

mark {
  background-color: $caribbean_green_25;
}

blockquote {
  font-size: 20px;
}

h1 {
  font-family: 'aventaextrabold';
  font-size: 2.875em;

  @media screen and (max-width: 1199px) {
    font-size: 2.5em;
  }
}

h2 {
  font-family: 'aventaextrabold';
  font-size: 35px;
}

h3 {
  font-family: 'aventabold';
  font-size: 30px;
}

h4 {
  font-family: 'aventaextrabold';
  font-size: 28px;
}

h5 {
  font-family: 'aventaextrabold';
  font-size: 25px;
}

h6 {
  font-family: 'aventaextrabold';
  font-size: 18px;
}


// TYPOGRAPHY COLOR
.electric_indigo {
  color: $electric_indigo;
}

.caribbean_green {
  color: $caribbean_green;
}

.energetic_yellow {
  color: $energetic_yellow;
}

//FORM
label.form-label {
  color: $electric_indigo;
  font-family: 'aventabold';
}

.form-control {
  &::-webkit-input-placeholder {
    color: $electric_indigo_50 !important;
  }

  &:-moz-placeholder {
    color: $electric_indigo_50 !important;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $electric_indigo_50 !important;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $electric_indigo_50 !important;
  }

  &:disabled {
    background-color: #D6D6D6;

    &[readonly] {
      background-color: #F0F0F0;
    }
  }

  &[readonly] {
    background-color: #F0F0F0;
  }

  &:disabled,
  &[readonly] {
    color: $black;

    &::-webkit-input-placeholder {
      color: $black !important;
    }

    &:-moz-placeholder {
      color: $black !important;
      opacity: 1;
    }

    &::-moz-placeholder {
      color: $black !important;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $black !important;
    }
  }

  &[type="password"] {
    font: caption;
    color: $electric_indigo_50;
  }

  &[type="file"] {
    color: $electric_indigo;

    &::-webkit-file-upload-button {
      background-color: $electric_indigo_75;
      color: $white;
      font-family: 'aventamedium';
    }

    &:hover {
      &::-webkit-file-upload-button {
        background-color: $electric_indigo !important;
      }
    }

    &:disabled {
      background-color: #F0F0F0;
      color: #8D8D8D;

      &::-webkit-file-upload-button {
        background-color: #D6D6D6 !important;
        color: #A2A2A2;
        font-family: 'aventamedium';
      }
    }
  }

  &.form-control-color {
    max-width: 5rem;
  }
}

.form-control-plaintext,
.col-form-label {
  color: $electric_indigo;
  font-family: 'aventamedium';
}

//COLOR PICKER
.color-picker-inline {
  .form-control-color {
    display: inline-block;
    margin-right: 1em;
  }
}

// ACCORDION
.accordion {
  .accordion-header {
    .accordion-button {
      color: $electric_indigo;
      font-size: 20px;
    }
  }

  .accordion-item {
    box-shadow: none;
    border: 0;
    border-bottom: 1px solid $electric_indigo_50;
    border-radius: 0px;
    margin-bottom: 0px;

    &:first-of-type {
      border-top: 1px solid $electric_indigo_50;
    }

    &:last-of-type {
      .accordion-button.collapsed {
        border-radius: 0px;
      }
    }
  }

  .accordion-body {
    padding-bottom: 30px;
  }

  .accordion-button {
    border-radius: 0px;

    &:not(.collapsed) {
      background-color: transparent;
      box-shadow: none;
    }

    &:focus {
      border-color: transparent;
      box-shadow: none;
    }

    &:after {
      flex-shrink: 0;
      width: 45px;
      height: 45px;
      margin-left: auto;
      content: "";
      background-repeat: no-repeat;
      background-size: 1.25rem;
      transition: transform 0.2s ease-in-out;
      background-color: $caribbean_green_50;
      background-position: center;
      border-radius: 50px;
    }
  }
}

// CARDS
.card {
  box-shadow: 0px 0px 16px $shadow !important;
  border: 0 !important;
  border-radius: 10px !important;

  .card-header {
    border-radius: 10px 10px 0 0 !important;
    color: $energetic_yellow;
    background-color: transparent;
    border-bottom: 0;
    padding: 30px;

    h4 {
      font-size: 20px;
    }

    &.card-tabs-navigation {
      padding: 30px 15px 0 15px;

      .nav-tabs {
        border-bottom: 0;

        .nav-link {
          border: 0;
          margin-bottom: 0;
          background-color: $electric_indigo_25;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          margin-right: 3px;

          &.disabled {
            color: $electric_indigo_50;
          }

          &.active {
            border: 0;
            margin-bottom: 0;
            background-color: $white;
          }
        }
      }
    }

    &.card-pills-navigation {
      padding: 15px;

      .nav.nav-pills {
        .nav-item {
          .nav-link {
            border-radius: 10px;
            background: $electric_indigo_25;
            margin-right: 3px;

            &.disabled {
              color: $electric_indigo_50;
            }

            &.active {
              background-color: $white;
            }
          }
        }
      }
    }
  }

  .card-header-image {
    height: 230px;
    background-position: center;
    background-size: cover;
  }

  .card-body {
    .title-card-body {
      font-size: 20px;
      padding-bottom: 16px;
      color: $electric_indigo;
    }

    padding: 30px;
  }

  .subtitle-card {
    font-size: 18px;
    color: $electric_indigo;
  }

  .text-card {
    font-size: 16px;
    color: $black;
    margin-bottom: 25px;

    .table {
      tr:first-child {
        border-top: 1px solid $electric_indigo_25;
      }

      td {
        border-color: $electric_indigo_25;
        color: $electric_indigo;
      }
    }
  }

  &.image-overlay-container {
    .card-img {
      border-radius: 10px;
    }

    .mask-image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $electric_indigo;
      opacity: .8;
      border-radius: 10px;
    }
  }

  .horizontal-card-img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-position: center;
    background-size: cover;
  }
}


// NAVBAR
.body-navbar {
  height: 100vh;
}

.navbar {
  margin-bottom: 20px;

  &.navbar-header {
    background-color: $electric_indigo;
    padding: 1em;
  }

  .nav-link {
    font-size: 16px;
    color: $energetic_yellow;

    &:hover,
    &:focus {
      color: $caribbean_green;
    }

    &.active {
      border-bottom: 2px solid $caribbean_green;
    }
  }

  .nav-item-icon {
    background-color: $caribbean_green;
    padding: 13px 12px;
    border-radius: 50px;
    margin-left: 3em;

    &:hover {
      background-color: $energetic_yellow;
      cursor: pointer;
    }
  }

  .custom-toggler.navbar-toggler {
    &:focus {
      box-shadow: none;
    }

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255 255 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }

  @media screen and (min-width: 992px) {
    .btn-close {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    .nav-item-icon {
      margin-left: 0em;
      text-align: center;
    }

    .nav-item {
      margin-top: .5em;
    }

    .navbar-nav {
      height: 100%;
    }

    .nav-link {
      display: inline-block;
    }

    .navbar-toggler {
      padding: .25rem 0rem
    }

    .btn-close {
      opacity: 1;
      background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
    }
  }

  .navbar-collapse {

    &.show,
    &.collapsing {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh !important;
      background-color: $electric_indigo;
      padding: 30px;
    }
  }

  .search-form-nav {
    @media screen and (min-width: 992px) {
      margin-left: 2em;
      margin-right: 1em;
    }

    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }

    position: relative;

    button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: auto;
      background-color: $electric_indigo_75;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: .25rem;
      border-bottom-right-radius: .25rem;

      svg {
        fill: $white;
      }
    }
  }
}

// SIDEBARS
.custom-sidebar {
  background-color: $electric_indigo_75;

  hr {
    background-color: $white;
  }

  .text-sidebar-header {
    margin-left: 10px;
    font-family: 'aventabold';
  }

  .dropdown-toggle {
    outline: 0;
  }

  .nav.nav-pills {
    & .nav-link {
      height: 50px;
      padding: 10px 30px;
      border-bottom: 0;
      border-radius: 10px;
      margin-bottom: 10px;

      &.link-item-sidebar {
        span {
          text-decoration: underline;
        }
      }

      span {
        color: $white;
        font-family: 'aventabold';
      }

      &.active {
        background-color: $white;

        span {
          color: $electric_indigo_75;
        }
      }

      &:hover {
        background-color: rgb(255 255 255 / 80%);

        span {
          color: $electric_indigo_75;
        }

        svg {
          fill: $electric_indigo_75;
        }
      }

      svg {
        margin-right: .5em;
      }

      &.disabled {
        opacity: .8;
      }
    }

    &>.nav-item>.nav-link {
      border-top: 0;
    }
  }
}

.custom-sidebar-dark {
  background-color: $white;
  box-shadow: 0px 2px 5px $dark;

  hr {
    background-color: $electric_indigo_75;
  }

  .text-sidebar-header {
    margin-left: 10px;
    color: $electric_indigo;
    font-family: 'aventabold';
  }

  .dropdown-toggle {
    outline: 0;
    color: $electric_indigo_75 !important;
  }

  .nav.nav-pills {
    & .nav-link {
      height: 50px;
      padding: 10px 30px;
      border-bottom: 0;
      border-radius: 10px;
      margin-bottom: 10px;

      &.link-item-sidebar {
        span {
          text-decoration: underline;
        }
      }

      span {
        color: $electric_indigo_75;
        font-family: 'aventabold';
      }

      svg {
        fill: $electric_indigo_75;
      }

      &.active {
        background-color: $electric_indigo_75;

        span {
          color: $white;
        }

        svg {
          fill: $white;
        }
      }

      &:hover {
        background-color: $electric_indigo_25;

        span {
          color: $electric_indigo_75;
        }

        svg {
          fill: $electric_indigo_75;
        }
      }

      svg {
        margin-right: .5em;
      }

      &.disabled {
        opacity: .8;
      }
    }

    &>.nav-item>.nav-link {
      border-top: 0;
    }
  }
}

.sidebar-minimal {
  .logo-sidebar-minimal {
    padding: 1em 0;
  }

  .nav.nav-pills {
    & .nav-link {
      padding: 15px 0px;
      height: 60px;
      border-radius: 0;
      margin-bottom: 0;

      &.active {
        border-radius: 0;
      }

      svg {
        margin-right: 0;
      }
    }
  }
}


// BUTTONS
.btn-primary {
  background-color: $caribbean_green_75;
  border-radius: 25px;
  border-color: $caribbean_green_75;
  color: $electric_indigo;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $caribbean_green;
    border-color: $caribbean_green;
    color: $electric_indigo;
  }
}

.btn-secondary {
  background-color: $electric_indigo;
  border-radius: 25px;
  border-color: $electric_indigo;
  color: $energetic_yellow;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $electric_indigo_75;
    border-color: $electric_indigo_75;
    color: $energetic_yellow;
  }
}

.btn-success {
  background-color: $success;
  border-radius: 25px;
  border-color: $success;
  color: $white;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $success_active;
    border-color: $success_active;
    color: $white;
  }
}

.btn-danger {
  background-color: $danger;
  border-radius: 25px;
  border-color: $danger;
  color: $white;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $danger_active;
    border-color: $danger_active;
    color: $white;
  }
}

.btn-warning {
  background-color: $warning;
  border-radius: 25px;
  border-color: $warning;
  color: $white;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $warning_active;
    border-color: $warning_active;
    color: $white;
  }
}

.btn-info {
  background-color: $electric_indigo_75;
  border-radius: 25px;
  border-color: $electric_indigo_75;
  color: $white;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $electric_indigo;
    border-color: $electric_indigo;
    color: $white;
  }
}

.btn-light {
  background-color: $light;
  border-radius: 25px;
  border-color: $light;
  color: $dark;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $light;
    border-color: $light;
    color: $dark;
  }
}

.btn-dark {
  background-color: $dark;
  border-radius: 25px;
  border-color: $dark;
  color: $light;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $dark;
    border-color: $dark;
    color: $light;
  }
}

.btn-link {
  color: $electric_indigo;

  &:hover {
    color: $electric_indigo_75;
  }
}

.btn-outline-primary {
  border-radius: 25px;
  border-color: $caribbean_green;
  color: $electric_indigo;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $caribbean_green;
    border-color: $caribbean_green;
  }
}

.btn-outline-secondary {
  border-radius: 25px;
  border-color: $electric_indigo;
  color: $electric_indigo;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $electric_indigo;
    border-color: $electric_indigo;
  }
}

.btn-outline-success {
  border-radius: 25px;
  border-color: $success;
  color: $success;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $success;
    border-color: $success;
  }
}

.btn-outline-danger {
  border-radius: 25px;
  border-color: $danger;
  color: $danger;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $danger;
    border-color: $danger;
  }
}

.btn-outline-warning {
  border-radius: 25px;
  border-color: $warning;
  color: $warning;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $warning;
    border-color: $warning;
    color: $white;
  }
}

.btn-outline-info {
  border-radius: 25px;
  border-color: $electric_indigo_75;
  color: $electric_indigo_75;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $electric_indigo_75;
    border-color: $electric_indigo_75;
    color: $white;
  }
}

.btn-outline-light {
  border-radius: 25px;
  border-color: $light;
  color: $light;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $light;
    border-color: $light;
    color: $white;
  }
}

.btn-outline-dark {
  border-radius: 25px;
  border-color: $dark;
  color: $dark;

  &:hover,
  &.active,
  &:focus,
  &:disabled {
    background-color: $dark;
    border-color: $dark;
  }
}

// ALERTS
.alert {
  svg {
    path.fa-primary {
      fill: transparent;
    }
  }

  .text-alert {
    padding-left: 16px;
  }

  &.alert-primary {
    color: $electric_indigo;
    background-color: $electric_indigo_25;
    border-color: $electric_indigo_25;
  }
}

// BADGE
.badge {
  border-radius: 50px;

  &.bg-primary {
    background-color: $caribbean_green !important;
    color: $white;
  }

  &.bg-secondary {
    background-color: $electric_indigo !important;
    color: $energetic_yellow;
  }

  &.bg-success {
    background-color: $success !important;
    color: $white;
  }

  &.bg-danger {
    background-color: $danger !important;
    color: $white;
  }

  &.bg-warning {
    background-color: $warning !important;
    color: $white;
  }

  &.bg-info {
    background-color: $electric_indigo_75 !important;
    color: $white;
  }
}

// BREADCRUMBS
.breadcrumb {
  padding-left: 0;

  li {
    display: inline-block;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    text-transform: none;
    font-size: 12px;
    font-weight: normal;
    color: $black;
    font-family: 'aventabold';

    a {
      color: $electric_indigo;
      font-size: 12px;
      font-family: 'aventabold';

      &:hover {
        text-decoration: underline;
      }
    }

    strong {
      font-weight: 400;
    }
  }

  .separator {
    color: $black;
  }
}

// LIST GROUP
.list-group {
  &.list-group-numbered>li::before {
    color: $electric_indigo;
  }

  .fw-bold {
    color: $electric_indigo;
    font-family: 'aventabold';
  }

  .list-group-item {
    border-bottom: 1px solid $electric_indigo_25;
    padding: 16px;
  }
}

// MODAL
.modal-header {
  color: $electric_indigo;
  border-bottom: 0;
}

.modal-footer {
  border-top: 0;
}

.modal-content {
  border-radius: 10px;
  border: 1px solid $electric_indigo_25;
}

// TOOLTIPS
.tooltip {
  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: $electric_indigo;
  }

  &[data-popper-placement='bottom'] {
    .tooltip-arrow::before {
      border-bottom-color: $electric_indigo;
    }
  }

  &[data-popper-placement='left'] {
    .tooltip-arrow::before {
      border-left-color: $electric_indigo;
    }
  }

  &[data-popper-placement='right'] {
    .tooltip-arrow::before {
      border-right-color: $electric_indigo;
    }
  }

  &[data-popper-placement='top'] {
    .tooltip-arrow::before {
      border-top-color: $electric_indigo;
    }
  }
}

// TOAST
.toast {
  border-radius: 10px;
  box-shadow: 0px 0px 16px $shadow;
  border: 0;

  .toast-header {
    color: $electric_indigo;
    border-bottom: 1px solid $electric_indigo_75;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px;

    small {
      color: $black;
    }

    .btn-close {
      opacity: 1;
      background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234F0BCD'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    }

    svg {
      margin-right: 10px;
    }
  }

  .toast-body {
    padding: 20px;
    color: $black;
  }
}


// NAV AND TABS
.nav {
  .nav-link {
    color: $electric_indigo;
    text-decoration: underline;
    font-family: 'aventabold';

    &.active {
      text-decoration: none;
    }

    &.disabled {
      text-decoration: none;
      color: $electric_indigo_25;
    }
  }

  &.nav-tabs {
    border-bottom: 1px solid $electric_indigo_25;

    .nav-link {
      text-decoration: none;

      &.active {
        border-color: $electric_indigo_25 $electric_indigo_25 $white;
        color: $electric_indigo;
      }
    }
  }

  &.nav-pills {
    .nav-link {
      text-decoration: none;
      padding: 14px 30px;

      &.active {
        background-color: $caribbean_green_75;
        color: $electric_indigo;
        border-radius: 10px;
      }
    }
  }
}


// RANGE
.custom-range::-webkit-slider-thumb {
  background: $caribbean_green;
}

.custom-range::-moz-range-thumb {
  background: $caribbean_green;
}

.custom-range::-ms-thumb {
  background: $caribbean_green;
}

// PAGINATION
.pagination {
  margin: 2em;

  .current {
    padding: 0;
    background: transparent;

    a {
      color: $white;
      background-color: #3491CF;
      border: 1px solid transparent;
      opacity: 1;
    }
  }

  li {
    background: transparent;

    a {
      color: $electric_indigo;
      background-color: #7CE0C8;
      border: 1px solid transparent;
      border-radius: 50px;
      padding-top: 8px;
      width: 60px;
      height: 40px;
      text-align: center;
      margin: 0 .5em;
      font-weight: bold;

      &:hover {
        color: $white;
        opacity: .8;
        background-color: $electric_indigo;
      }
    }
  }

  .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    border-radius: 50px;
  }
}

// PROGRESS
.progress {
  background-color: #E8E8E8;
  border-radius: 23px;
  margin-bottom: 1em;

  .progress-bar {
    &:first-of-type {
      border-top-left-radius: 23px;
      border-bottom-left-radius: 23px;
    }

    &:last-of-type {
      border-top-right-radius: 23px;
      border-bottom-right-radius: 23px;
    }
  }
}

// INPUT GROUP
.input-group {
  .dropdown-menu {
    border: 0;
    box-shadow: 0px 0px 16px $shadow;

    a {
      color: $electric_indigo;
    }

    .dropdown-divider {
      border-top: 1px solid $electric_indigo_50;
    }
  }

  .input-group-text {
    background-color: $electric_indigo_25;
    color: $electric_indigo;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $electric_indigo_50;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $electric_indigo_50;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: $electric_indigo_50;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: $electric_indigo_50;
  }

  .btn {
    background-color: $electric_indigo_75;
    color: $white;

    &:hover {
      background-color: $electric_indigo;
    }
  }
}

// FORM Check
.form-check {
  .form-check-input {
    border: 1px solid $electric_indigo_50 !important;

    &:checked {
      background-color: $electric_indigo;
      border-color: $electric_indigo;
    }
  }
}

.checkbox-toggle-button,
.radio-toggle-button {
  .btn-primary {
    background-color: $caribbean_green_50;
    border-color: $caribbean_green_50;
  }

  .btn-check:checked+.btn-primary {
    background-color: $caribbean_green;
    border-color: $caribbean_green;
    color: $electric_indigo;
  }
}

.outlined-styles {
  .btn-check:checked+.btn-outline-secondary {
    border-radius: 25px;
    border-color: $caribbean_green;
    color: $white;
    background-color: $electric_indigo;
  }
}

// FORM FLOATING
.form-floating {

  label,
  .form-control,
  .form-select {
    color: $electric_indigo;
  }
}

// SELECT
.form-select {
  color: $electric_indigo;

  option {
    padding: 5px 10px;
    border-radius: 5px;
  }

  option:checked {
    background-color: $electric_indigo_25;
    color: $electric_indigo;
  }

  &:disabled {
    color: $black;
  }
}

// DROPDOWN
.dropdown {
  .dropdown-menu {
    border-radius: 5px;
    border: 0;
    box-shadow: 0px 0px 16px $shadow;

    a {
      color: $electric_indigo;

      &:hover {
        background-color: $caribbean_green_25;
      }

      &.disabled {
        color: $electric_indigo_25;
      }
    }

    .dropdown-divider {
      border-top: 1px solid $electric_indigo_50;
    }
  }
}

// TABLE
.table {
  &>:not(caption) {
    &>* {
      &:last-of-type {
        &>* {
          border-bottom-width: 0;
        }
      }

      &>* {
        border-bottom-color: $electric_indigo_25;
        padding: 1em;

        @media screen and (max-width: 1260px) {
          min-width: 200px;
        }
      }
    }
  }

  .table-active {
    background-color: rgb(80 214 182 / 19%);
    box-shadow: none;
  }

  thead,
  tbody {
    tr {
      th {
        color: $electric_indigo;
      }
    }
  }

  thead {
    svg {
      margin-left: 10px;
    }
  }

  tbody {
    box-shadow: 0px 0px 16px $shadow;
    border-radius: 10px;

    svg {
      margin-right: 10px;
    }
  }

  .icon_download {
    @media screen and (max-width: 1260px) {
      min-width: 100px !important;
    }

    svg {
      fill: $caribbean_green;
      margin-right: 0;
    }
  }
}

//CAROUSEL
.carousel-indicators [data-bs-target] {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: $energetic_yellow;
  opacity: 1;
}

.carousel-indicators [aria-current="true"] {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: $caribbean_green;
}

.carousel-control-prev {
  justify-content: left;
  opacity: 1;

  .carousel-control-prev-icon {
    background-color: $caribbean_green;
    background-size: 30px;
    background-position: 30%;
    padding: 3em 1.5em;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234F0BCD'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  }
}

.carousel-control-next {
  justify-content: flex-end;
  opacity: 1;

  .carousel-control-next-icon {
    background-color: $caribbean_green;
    background-size: 30px;
    background-position: 70%;
    padding: 3em 1.5em;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234F0BCD'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
}