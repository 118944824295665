/*** FONT ***/
@font-face {
  font-family: 'aventaregular';
  src: url('../../../font/aventa-regular-webfont.woff2') format('woff2'),
  url('../../../font/aventa-regular-webfont.woff') format('woff'),
  url('../../../font/aventa-regular-webfont.svg#aventaregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aventamedium';
  src: url('../../../font/aventa-medium-webfont.woff2') format('woff2'),
  url('../../../font/aventa-medium-webfont.woff') format('woff'),
  url('../../../font/aventa-medium-webfont.svg#aventamedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aventabold';
  src: url('../../../font/aventa-bold-webfont.woff2') format('woff2'),
  url('../../../font/aventa-bold-webfont.woff') format('woff'),
  url('../../../font/aventa-bold-webfont.svg#aventabold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aventaextrabold';
  src: url('../../../font/aventa-extrabold-webfont.woff2') format('woff2'),
  url('../../../font/aventa-extrabold-webfont.woff') format('woff'),
  url('../../../font/aventa-extrabold-webfont.svg#aventaextrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}

p,span,label,a,ul,ol,li,div{
  font-family: 'aventamedium';
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 24px;
}

h1{
  font-family: 'aventaextrabold';
  font-size: 2.875em;
}

h2{
  font-family: 'aventaextrabold';
  font-size: 35px;
}

h3{
  font-family: 'aventabold';
  font-size: 30px;
}

h4{
  font-family: 'aventaextrabold';
  font-size: 28px;
}

h5{
  font-family: 'aventaextrabold';
  font-size: 25px;
}

h6{
  font-family: 'aventaextrabold';
  font-size: 18px;
}
